import { Component, OnInit } from '@angular/core';
import { ServiviciosService } from '../services/servicios.service';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css']
})
export class ServiciosComponent implements OnInit {

  public productos:any [];
  public prod_electro:any [];
  public prod_electri:any [];
  public prod_auto:any [];

  constructor(private productoService: ServiviciosService) {


      this.productos = this.productoService.getServicios();

  }

  ngOnInit(): void {
    this.separarProductos();
  } 

  private separarProductos(){
    var prod1 = []
    var prod2 = []
    var prod3 = []
    this.productos.forEach(producto => {
      switch(producto.categoria){
        case 'electrica':
            prod1.push(producto);
          break;
        case 'automotriz':
          prod2.push(producto);
          break;
        case 'electronica':
          prod3.push(producto);
          break;
      }
    });

    this.prod_electri = prod1;
    this.prod_auto = prod2;
    this.prod_electro = prod3;  

  }

}
