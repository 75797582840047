import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProductosService {

  private productos: any[] = [
    {
      id: "Equipo_de_comunicación",
      nombre: "Equipo de comunicación",
      descripcion: "Tenemos todo tipo de equipo de comunicación cableada e inalámbrica, switches, equipo de enrutamiento, convertidores de protocolos y medios. Sistemas comerciales y a la medida, como por ejemplo:",
      datos: [
        "Routers y switches de diferentes marcas",
        "Convertidores de Ethernet a Fibra Óptica",
        "Convertidores de protocolos seriales (RS-232, RS-422, RS485) a Fibra Óptica",
        "Sistemas de radios para comunicación industrial",
        "Sistemas de comunicación remota por medio de cables de potencia",
        "Concentradores de información y servidores, así como sistemas a la medida para análisis de información concentrada"
      ],
      textoAux: "Trabajamos contigo para que el protocolo, medio o dato no sea un problema para la comunicación entre tus sistemas.",
      img: "routers_01.jpg",
      estatus: 1,
      categoria: "electronica"
    },
    {
      id: "Video_Vigilancia",
      nombre: "Sistemas de video vigilancia",
      descripcion: "Manejamos diversas marcas de cámaras y equipo para instalación acorde a la necesidad del proyecto. Camaras PTZ o de bala, infrarrojas," +
        " con comunicación a internet y almacenamiento local, inalámbricas, independientes o con conexión a DVR (Digital Video Recorder). " +
        "Para que un sistema de seguridad funcione correctamente no solo se trata de la instalación.",
      img: "vigilancia_01.jpg",
      estatus: 1,
      categoria: "electronica"
    },
    {
      id: "Sensores_y_actuadores",
      nombre: "Sensores y actuadores",
      descripcion: "Contamos con diversas marcas de sensores de temperatura, presión de aire, agua o aceite, humedad, presencia, proximidad, luz, etc., analógicos y digitales. " +
        "También contamos con diversos actuadores neumáticos, hidráulicos y eléctricos para diferentes aplicaciones. " +
        "El mundo de los sensores y actuadores es inmenso. Contáctanos y te ayudamos a encontrar la mejor opción.",
      img: "sensores_01.png",
      estatus: 1,
      categoria: "electronica"
    },
    {
      id: "Localizacion_GPS_y_Control_via_GSM",
      nombre: "Sistemas de localización GPS con comunicación y control vía GSM",
      descripcion: "Desde lo básico hasta lo más completo, contamos con diversos dispositivos GPS que te ayudan a localizar tus vehículos. También tenemos equipo para controlar de manera remota la alarma (te avisa si lo mueven) y el encendido del mismo. Todo esto de manera sencilla gracias a nuestra propia aplicación celular.",
      img: "rastreo-gps_01.jpg",
      estatus: 1,
      categoria: "automotriz"
    },
    {
      id: "Alarmas_y_sistemas_de_encendido_remoto.",
      nombre: "Alarmas y sistemas de encendido remoto.",
      descripcion: "Contamos con diversos sistemas de alarma que se acoplan a cualquier vehículo. Nuestros dispositivos ayudarán a proteger tu inversión y en muchos casos herramienta de trabajo ya que hoy día los niveles de robo de vehículos y motos se han disparado. " +
        "Tenemos diversas opciones, desde una alarma sencilla hasta sistemas con encendido remoto por medio de control. " +
        "Acércate a nosotros y podemos asesorarte en la mejor opción.",
      img: "encendido_01.jpg",
      estatus: 1,
      categoria: "automotriz"
    },
    {
      id: "Cerrajeria_Automotriz",
      nombre: "Llaves perdidas, duplicados y controles. (Con o sin chip). Insumos de cerrajería",
      descripcion: "En MOBRA Tecnología nos enfocamos en la seguridad de tu vehículo y es por ello que parte de nuestro trabajo también se enfoca en los insumos para duplicados y copias de llaves con y sin chip además de controles y accesorios enfocados a los sistemas de seguridad del vehículo. " +
        "Tenemos precio especial a mayoristas y manejamos también insumos de cerrajería como Lishis, Easy Readers, Direct Readers, programadoras para llaves, cortadoras, chapas, equipo para reparación, etc.",
      img: "llaves_01.jpg",
      estatus: 1,
      categoria: "automotriz"
    },
    {
      id: "Sensores_y_monitores_de_transformadores",
      nombre: "Sensores y monitores de transformadores",
      descripcion: "Este es uno de los ramos especializados de MOBRA Tecnología. Lo que pudiéramos colocar de información en este apartado es no limitativo a lo que manejamos así que ante cualquier duda contáctanos y con gusto revisamos tu requerimiento. " +
        "Contamos con sensores para transformadores de:",
      datos: [
        "Temperatura de aceite",
        "Humedad en Aceite",
        "Contenido de partículas en aceite",
        "Voltaje y corriente del transformador",
        "Voltaje y corriente de los sistemas periféricos como Sistemas de Enfriamiento (ventiladores y bombas)",
        "Nivel de aceite",
        "Contenido de Hidrógeno, Humedad y Temperatura en un solo sensor",
        "Gases Disueltos en Aceite (Diferentes Tecnologías)",
        "Mediciones eléctricas en aceite (Voltaje de Ruptura, Factor de Potencia, etc)"

      ],
      textoAux: "Si tienes algún requerimiento con gusto podemos revisarlo y ofrecerte una solución.",
      img: "monitoreo_aceite_01.jpg",
      estatus: 1,
      categoria: "electrico"
    },
    {
      id: "Máquinas_de_tratamiento_de_aceite",
      nombre: "Máquinas de tratamiento de aceite",
      descripcion: "El aceite, ya sea aislante o lubricante, es un elemento INDISPENSABLE en las máquinas eléctricas y que se encuentra en elementos como transformadores y generadores. Es ya conocido que el estado del aceite te da indicios del funcionamiento y estado de las máquinas y, además te ayuda con otros problemas como enfriar y limpiar los sistemas, sin embargo, uno de los principales problemas con el aceite es ¿qué hago con él?"+
          "Un litro de aceite usado que no sea tratado o reciclado puede llegar a contaminar hasta 40,000 litros de agua potable. Lo mejor con el aceite siempre será poder volver a utilizarlo. "+
          "Es por eso que, como parte de nuestro compromiso con el medio ambiente, trabajamos con soluciones para re-utilizar este fluido. "+
          "En MOBRA Tecnología contamos con máquinas que vuelven simple el tratamiento de aceite, algunas de estas son:",
      datos: [
        "Máquinas filtradoras de aceite (filtración en micrones y conforme a estándar ISO). Con sensores en línea para conocer el avance de las filtradoras. ",
        "Máquinas desgasificadoras de aceite y con eliminación de humedad (eliminan los gases y la humedad del aceite del transformador por diversos métodos como el calentamiento y el vacío). Con diversas capacidades de procesamiento (400 a 20 mil litros por hora) y compatibles con uso en línea del transformador. ",
        "Máquinas de regeneración de aceite por medio de filtraciones. Puedes volver a utilizar tu aceite dañado después de filtrarlo en la máquina y dejarlo con características adecuadas de operación. "
      ],
      textoAux: "En todos los casos, nuestras máquinas pueden ser hechas de acero inoxidable y contamos también con versiones contra explosiones para ambientes hostiles como minas, plataformas marítimas y fabricas militares o navales.",
      img: "maquina_aceite_02.jpg",
      estatus: 1,
      categoria: "electrico"
    },
    {
      id: "Equipos_de_mediciones_portátiles_para_transformadores",
      nombre: "Equipos de mediciones portátiles para transformadores",
      descripcion: "Cuando se tiene un amplio número de transformadores de potencia y sobre todo de potencias bajas, no es necesario tener equipos en cada uno de los transformadores. "+
          "La manera más eficiente de verificar el funcionamiento y condición actual del transformador es a través de pruebas del aceite de manera portátil. "+
          "Es por eso que en MOBRA Tecnología contamos con equipos para diversas mediciones como:",
      datos: [
        "Humedad en el Aceite",
        "Rigidez Dieléctrica del Aceite",
        "Kits de pruebas rápidas para Acidez y PCBs (Bifenilos Policrorados)"
      ],
      textoAux: "Estos son solo algunos de los equipos que manejamos. Acércate a nosotros si tienes algún otro requerimiento el cuál seguramente podremos atender. ",
      img: "medidor_01.jpg",
      estatus: 1,
      categoria: "electrico"
    },
    {
      id: "Equipos_concentradores_y_de_interpretación_de_datos_de_transformadores",
      nombre: "Equipos concentradores y de interpretación de datos de transformadores",
      descripcion: "Tener sensores y monitores es solo un paso. ¿Qué sigue después? Evaluar los datos obtenidos. "+
          "Imagina que una Central Hidroeléctrica en Chiapas tiene 12 transformadores (algo real). "+
          "De esos 12 transformadores se tienen sensores para humedad, hidrógeno en el aceite, temperatura, voltaje, corriente y nivel de aceite, es decir, 6 variables (algo básico). Esas variables son medidas en tiempo real y se guarda su valor cada 10 minutos (algo conservador). 12 x 6 x 6 (cada hora) x 24 cada día) = 10, 368 valores diarios. ¿quién los va a analizar? "+
          "La capacidad de análisis de los datos es algo que en definitiva debe dejársele a un sistema automatizado si no quieres volver loco al encargado. No existe un humano que pueda evaluar tal capacidad de datos sin dejar de lado actividades más importantes en una central/subestación.",
      textoAux: "En MOBRA Tecnología contamos con equipos que además de tener la capacidad de almacenar los datos al comunicarse de manera directa con sensores y monitores en el transformador, también tienen la capacidad de interpretar los datos y mostrarte la información directa para una toma de decisión eficaz. "+
          "On-Line Trace Transformer es un sistema “traje a la medida” que programaremos conforme a los sistemas que ya tienes instalados en tus transformadores y generadores y con el cual podremos evaluar todos los datos que arrojan esos monitores. "+
          "Las evaluaciones que el sistema te proporciona son conforme a las principales normas internacionales para conocer el estado del transformador y generador. "+
          "Enfócate en lo importante (la toma de decisiones) y junto a nosotros volvamos simple la interpretación de la información. "+
          "Si tienes dudas sobre cómo trabaja nuestro sistema, contáctanos y con gusto te daremos los por menores.",
      img: "Sensores_01.jpg",
      estatus: 1,
      categoria: "electrico"
    }
  ];

  constructor() { }

  public getProductos() {
    return this.productos;
  }

  public get4Producto() {

    var valores = [];

    while (valores.length < 4) {
      var valor = this.productos[this.getRandom(1, this.productos.length)];
      var flag = false;
      valores.forEach(element => {
        if (element == valor) {
          flag = true;
        }
      });
      if (!flag) {
        valores.push(valor);
      }

    }

    return valores;
  }

  private getRandom(min: number, max: number) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  public getProducto(id: string) {
    var encontrado;
    this.productos.forEach(element => {
      if (element.id == id) {
        encontrado = element;
        console.log(encontrado);
      }
    });

    return encontrado;

  }


}
