import { Component, OnInit } from '@angular/core';
import { ServiviciosService } from '../services/servicios.service';
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-servicio',
  templateUrl: './servicio.component.html',
  styleUrls: ['./servicio.component.css']
})
export class ServicioComponent implements OnInit {

  public producto:any;
  public id:any; 

  constructor(private ruta:ActivatedRoute, private prodService:ServiviciosService) {
      this.ruta.params.subscribe((param)=>{
        this.id = param['id'];
        this.producto = prodService.getServicio(this.id);
      })

   }

  ngOnInit(): void {
  }

}
