import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactoComponent } from './components/contacto/contacto.component';
import { HomeComponent } from './components/home/home.component';
import { NosotrosComponent } from './components/nosotros/nosotros.component';
import { ServiciosComponent } from './components/servicios/servicios.component';
import { ServicioComponent} from './components/servicio/servicio.component';
import { ProductosComponent } from './components/productos/productos.component';
import { ProductoComponent } from './components/producto/producto.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent},
  { path: 'nosotros', component: NosotrosComponent},
  { path: 'contacto', component: ContactoComponent},
  { path: 'servicios', component: ServiciosComponent},
  { path: 'servicio/automotriz/:id', component: ServicioComponent},
  { path: 'servicio/electronica/id', component: ServicioComponent},
  { path: 'servicio/electrica/:id', component: ServicioComponent},
  { path: 'productos', component: ProductosComponent},
  { path: 'producto/automotriz/:id', component: ProductoComponent},
  { path: 'producto/electronica/:id', component: ProductoComponent},
  { path: 'producto/electrica/:id', component: ProductoComponent},
  { path: '**', pathMatch: 'full', redirectTo: 'home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
