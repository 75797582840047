<app-header></app-header>
<div class="barra-flotante">
    <app-navbar></app-navbar>
</div>
<app-carrusel></app-carrusel>
<div class="container-fluid">
    <div class="card mt-4 nosotros">
        <div class="row no-gutters">
            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 dv-img">
                <img src="assets/img/nosotros/mexico-01.jpg" class="d-block w-100 rounded-lg" alt="Mapa de México">
            </div>
            <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8">
                <div class="card-body">
                    <h2 class="card-title titulo">¿Quiénes somos?</h2>
                    <p class="card-text text-justify who-text">
                        Empresa Mexicana de servicios y comercialización de equipo eléctrico y electrónico. Dedicados a media y alta potencia, equipo de iluminación y dispositivos electrónicos de monitoreo, rastreo, comunicación y control en general.
                        Con experiencia de más de 10 años instalando, diagnosticando, reparando y comercializando sistemas electrónicos en la industria eléctrica y automotriz. 
                        Por fin consolidados como empresa en 2020, un año difícil y lleno de retos, pero el momento perfecto para confiar en la experiencia del personal de MOBRA y además con un mercado mexicano con futuro prometedor. Creada y trabajando con la idea en la mente de que hoy más que nunca es cuando se necesitan soluciones de nuestro estilo en México.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="card mt-4 nosotros">
        <div class="row no-gutters">
            <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8">
                <div class="card-body">
                    <h2 class="card-title titulo">Misión | Visión</h2>
                    <p class="card-text text-justify who-text">
                        <strong>Misión:</strong>
                        Construir las mejores soluciones comerciales y tecnológicas para el mercado mexicano de las industrias eléctrica, electrónica y automotriz a un precio asequible.
                    </p>
                    <p class="card-text text-justify who-text">
                        <strong>Visión:</strong>
                        Ser líderes a nivel nacional de la resolución de problemas cotidianos de las industrias eléctrica, electrónica y automotriz con nuestros productos y servicios.
                    </p>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 dv-img">
                <img src="assets/img/nosotros/futuro_01.jpeg" class="d-block w-100 rounded-lg" alt="Mapa de México">
            </div>
        </div>
    </div>
    <div class="card mt-4 nosotros">
        <div class="row no-gutters">
            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 dv-img">
                <img src="assets/img/nosotros/valores_01.jpg" class="d-block w-100 rounded-lg" alt="Mapa de México">
            </div>
            <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8">
                <div class="card-body">
                    <h2 class="card-title titulo">Valores</h2>
                    <div class="card-text text-justify who-text">
                        <ul>
                            <li><strong>Integridad y alineación con la legalidad.</strong></li>
                            <li><strong>Honestidad:</strong> Vamos a trabajar con base en lo que tenemos y seremos claros contigo sobre lo que se debe realizar para buscar la mejor opción para ti</li>
                            <li><strong>Mejora continua:</strong> Buscaremos de la mano de nuestros colaboradores y clientes la mejor solución para sus actividades cotidianas para que se pueda enfocar en las actividades de “expertise” de su ramo.</li>
                            <li><strong>Consientes de nuestro entorno:</strong> Todos nuestros productos y servicios serán desarrollados con plena responsabilidad del entorno en el cuál serán aplicados. Pensando en las personas, el medio ambiente y el contexto completo de funcionamiento donde funcionarán.</li>
                            <li><strong>Visión futura:</strong> Hacemos nuestro mejor esfuerzo para que los productos y servicios sean una solución a mediano y largo plazo de tus necesidades.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
