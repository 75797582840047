import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServiviciosService {

  private servicios: any[] = [
    {
      id: "CCTV_y_Monitoreo",
      nombre: "Sistemas de Circuito Cerrado y Monitoreo",
      descripcion: "Trabajamos en el cuidado de tus bienes " +
        "y patrimonio. Sabemos que la delincuencia no descansa y nosotros queremos que tú te enfoques en tus actividades para dejarnos " +
        "cuidar de los entornos que requieras. Podrás observar todo desde tu celular, Tablet o computadora. Realizamos el monitoreo adecuado" +
        " a tus requerimientos de casas, oficinas, negocios, plazas y bodegas. PLUS: Realizamos monitoreo en línea de vehículos.",
      img: "cctv_01.jpg",
      estatus: 1,
      categoria: "electronica"
    },
    {
      id: "Automatizacion",
      nombre: "Automatización de procesos",
      descripcion: "Piensa en un proceso clave de tu industria/trabajo. ¿Creer que se puede hacer más rápido y eficiente? Nosotros sabemos que" +
        " sí y seguramente podemos ayudarte a ello aplicando procedimientos en los cuales se involucre alguna máquina, sensores y accionadores" +
        " que faciliten el trabajo de las demás personas y vuelvan tu proceso de mayor eficiencia. " +
        "Normalmente este tipo de procedimientos son trabajos tipo “traje a la medida”, por lo tanto, lo mejor es que te acerques a nosotros para " +
        "evaluar tu proceso y ofrecerte la mejor solución.",
      img: "control_01.png",
      estatus: 1,
      categoria: "electronica"
    },
    {
      id: "Localizacion_y_rastreo",
      nombre: "Localización y rastreo GPS de autos y motos",
      descripcion: "En la actualidad, es una necesidad el saber donde está exactamente tu vehículo. Como proveedores y usuarios del sistema te podemos " +
        "decir que no existe mayor paz y tranquilidad que conocer exactamente donde se encuentran tus vehículos. Es por eso que tenemos el mejor sistema " +
        "de localización vía GPS y además el más sencillo de utilizar. " +
        "Tenemos una APP propia y la manera más simple de controlar hasta el encendido o apagado de tu vehículo. Solamente preocúpate por continuar con tus actividades.",
      img: "GPS_01.jpg",
      estatus: 1,
      categoria: "automotriz"
    },
    {
      id: "Arranque_remoto",
      nombre: "Sistema de arranque remoto",
      descripcion: "¿Has escuchado el término “remote start”? Se trata de un sistema pare encender tu vehículo a distancia por comodidad (encender aire acondicionado" +
        " o calefacción) o simplemente por tener el gusto de hacerlo. " +
        "Nosotros tenemos el sistema adecuado para ello.",
      img: "arranque_01.jpg",
      estatus: 1,
      categoria: "automotriz"
    },
    {
      id: "Cerrajería_Automotriz",
      nombre: "Cerrajería Automotriz",
      descripcion: "Elaboración desde cero de llaves perdidas, así como duplicados de llaves, tanto mecánicas como “smart”, es decir, las llaves que solo portas para " +
        "encender el vehículo a través de algún botón. " +
        "Manejamos prácticamente todos los tipos de llaves y controles de todas las marcas de vehículos (incluidos vehículos de lujo). " +
        "Tenemos también precio preferencial a mayoristas.",
      img: "Cerrajeria_01.jpg",
      estatus: 1,
      categoria: "automotriz"
    },
    {
      id: "Computadoras_de_vehiculos",
      nombre: "Revisión y programación de computadoras de vehículos",
      descripcion: "Manejamos varios servicios de programación en los vehículos, principalmente aquellos que tienen que ver con los sistemas de seguridad de encendido. " +
        "Programación de computadoras, números de serie, borrado de códigos, emparejamiento de módulos, etc. " +
        "Si tienes duda sobre algún servicio en particular, llámanos y con gusto lo verificamos. ",
      img: "computadora_auto_01.jpeg",
      estatus: 1,
      categoria: "automotriz"
    },
    {
      id: "Pruebas_fisico_quimicas",
      nombre: "Pruebas físico – químicas al aceite aislante y lubricante",
      descripcion: "La mejor manera de conocer el funcionamiento de las máquinas electro – mecánicas es con análisis a los aceites de las mismas." +
        " Ya sea el aceite aislante en los transformadores como el aceite lubricante y grasasen los generadores o motores, debes contar con los análisis " +
        "adecuados para conocer el estado y funcionamiento de tus máquinas.",
      datos: [
        "Aspecto físico",
        "Temperatura de escurrimiento",
        "Análisis de Gases Disueltos",
        "Contenido de PCBs (Askareles)",
        "Contenido de Furanos",
        "Contenido de Humedad",
        "Acidéz",
        "Color",
        "Viscosidad cinemática",
        "Punto de inflamación",
        "tenemos más de 40 pruebas, pregúntanos por las demás.",

      ],
      img: "aceite_01.jpg",
      estatus: 1,
      categoria: "electrica"
    },
    /*
    {
      id: "Filtrado_de_aceite",
      nombre: "Servicio de filtrado de aceite lubricante",
      descripcion: "Contamos con la renta de maquinaria o servicio completo de filtración de aceite. " +
        "También contamos con otros servicios como pasivación de transformadores, retiro de humedad y desgasificación. " +
        "Pregúntanos si tienes duda de algún otro servicio.",
      img: "filtro_01.jpg",
      estatus: 1,
      categoria: "electrica"
    },*/
    {
      id: "Asesoria_transformadores",
      nombre: "Asesoría en detección del estado de transformadores de potencia",
      descripcion: "Conocer el estado de un elemento tan complejo como un transformador de potencia puede llegar a ser abrumador, " +
        "sobre todo cuando no es tu principal actividad en el trabajo. Sin embargo, nosotros podemos ayudarte con esa tarea. " +
        "Permítenos asesorarte en conocer el estado, funcionamiento y prevención de alguna falla en tu transformador. Podemos seguir" +
        " la huella de funcionamiento de un transformador de baja, media y alta potencia. Además, contamos con la practicidad del almacenamiento" +
        " de los datos de mismo gracias a “Trace Transformer”, nuestro software de almacenamiento e interpretación de pruebas a transformadores.",
      img: "transformadores_01.jpg",
      estatus: 1,
      categoria: "electrica"
    },
    {
      id: "Reparacion_Moniteo_aceite",
      nombre: "Reparación de sistemas de monitoreo de aceite",
      descripcion: "Existen varios tipos de monitores de aceite en transformadores. En México, existen tecnologías instaladas como cromatografía de gases," +
        " espectroscopía foto-acustica, medición por membrana, medición directa por sensor inmerso y resistividad. " +
        "En MOBRA TECNOLOGÍA contamos con personal capacitado para diagnosticar y reparar estos monitores y ofrecerte la mejor solución para no dejar sin" +
        " monitorear tus transformadores. Contáctanos para evaluar tu caso específico, sabemos que podemos ayudarte.",
      img: "monitoreo_aceite.jpg",
      estatus: 1,
      categoria: "electrica"
    },
    {
      id: "Reparacion_variables_fisicas",
      nombre: "Reparación de sistemas de monitoreo de variables físicas de un transformador",
      descripcion: "En MOBRA TECNOLOGÍA contamos con personal experto en sistemas de monitoreo de variables de un transformador ya que tenemos el record de" +
        " instalación de sistemas de la gama más completa en México. " +
        "Contáctanos para evaluar tu caso específico, sabemos que podemos ayudarte.",
      img: "reparacion_01.jpg",
      estatus: 1,
      categoria: "electrica"
    },
    {
      id: "Iluminacion",
      nombre: "Asesoría para iluminación de casas, oficinas, negocios, plazas y bodegas",
      descripcion: "Iluminar una zona no se trata simplemente de colocar un foco. Si tú quieres tener un ambiente eficiente, funcional y adecuado a las actividades" +
        " específicas a realizar, debes conocer al menos la luminancia e iluminancia, potencia, tipo de luz y colocación de las luminarias. " +
        "¿Sabías que un estudio reveló que el 68% de oficinistas ha tenido algún tipo de molestia con la iluminación de su área de trabajo? Imagina cuanto puede" +
        " bajar la productividad en un taller u oficina por tener una inadecuada colocación de luminarias. " +
        "Mejor asesórate con nosotros y te ofreceremos soluciones integrales de correcta iluminación en tus áreas de trabajo o descanso.",
      img: "iluminacion-01.jpg",
      estatus: 1,
      categoria: "electrica"
    },
    {
      id: "Paneles_solares",
      nombre: "Instalación residencial, comercial e industrial de paneles solares",
      descripcion: "La industria de la generación de energía eléctrica por medio del sol ha incrementado de manera gigante en los últimos 5 años en México, " +
        "sin embargo, no es una industria nueva en el mundo. " +
        "En MOBRA Tecnología tenemos amplio conocimiento sobre normativas, requerimientos y estándares internacionales para la instalación y correcto " +
        "funcionamiento de todos los dispositivos involucrados en la generación de energía a través de paneles solares. Se trata de la correcta instalación, " +
        "conexión, cableado, canalización y montaje de elementos como paneles, inversores, micro-inversores, controladores, centralizadores y otros varios " +
        "dependiendo el proyecto. " +
        "Contamos con capacidad y experiencia para instalar proyectos desde 2 hasta más de 400 paneles, sin embargo, sabemos que cada proyecto está hecho a la" +
        " medida de los requerimientos del cliente, por lo tanto, podemos ayudarte también con los cálculos para conocer el tipo de proyecto que requieres con " +
        "respecto a tu consumo de energía eléctrica. " +
        "Contáctanos para revisar tu caso y poder ofrecerte la solución más económica, eficiente y funcional para que dejes de tirar el dinero en grandes cuentas de luz.",
      img: "paneles_01.jpg",
      estatus: 1,
      categoria: "electrica"
    }
  ];



  constructor() {

  }

  public get4Servicios() {

    var valores = [];

    while (valores.length < 4) {
      var valor = this.servicios[this.getRandom(1, this.servicios.length)];
      var flag = false;
      valores.forEach(element => {
        if (element == valor) {
          flag = true;
        }
      });
      if (!flag) {
        valores.push(valor);
      }

    }

    return valores;
  }

  private getRandom(min: number, max: number) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  public getServicio(id: string) {
    var encontrado;
    this.servicios.forEach(element => {
      if (element.id == id) {
        encontrado = element;
        console.log(encontrado);
      }
    });

    return encontrado;

  }

  public getServicios(){
    return this.servicios;
  }

}
