import { Component, OnInit } from '@angular/core';
import { ProductosService } from '../services/productos.service';
 
@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.css']
})
export class ProductosComponent implements OnInit {

  public productos:any [];
  public prod_auto:any [];
  public prod_electri:any [];
  public prod_electro:any [];

  constructor( private prodServ:ProductosService ) {
    this.productos = this.prodServ.getProductos();
    
   }

  ngOnInit(): void {
    this.separarProductos();
  }


  private separarProductos(){
    var prod1 = []
    var prod2 = []
    var prod3 = []
    this.productos.forEach(producto => {
      switch(producto.categoria){
        case 'electrico':
            prod1.push(producto);
          break;
        case 'automotriz':
          prod2.push(producto);
          break;
        case 'electronica':
          prod3.push(producto);
          break;
      }
    });

    this.prod_electri = prod1;
    this.prod_auto = prod2;
    this.prod_electro = prod3;  

  }

}
