<nav class="navbar navbar-expand-lg navbar-dark header-brand">
    <a class="navbar-brand" [routerLink]="['/home']">
        <img class="brand-logo" src="assets/img/logo-mobra.png" width="50" alt="Mobra logo">
        <span class="brandname">
            <strong>MOBRA</strong>
            |  Tecnología
        </span>
    </a>
    <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#menuForMovil"
        aria-controls="menuForMovil"
        aria-expanded="false"
        aria-label="Toggle navigation"
    >
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="menuForMovil">
        <ul class="navbar-nav mr-auto" id="botonesMenu" >
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/home']">Inicio</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/nosotros']">Nosotros</a>
            </li>
            <li class="nav-item " routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/servicios']">Servicios</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/productos']"  >Productos</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" >Nuestras marcas</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/contacto']">Contácto</a>
            </li>
        </ul>
        <div class="icon-redes">
            <a href="https://web.facebook.com/MOBRATECNOLOGIA" target="_blank">
                <img class="btn-redes" src="assets/img/facebook.svg" alt="Facebook logo">
            </a>
            <a href="https://twitter.com/TecnologiaMobra" target="_blank">
                <img class="btn-redes" src="assets/img/twiter.svg" alt="Twitter logo">
            </a>
            <a href="https://api.whatsapp.com/send?phone=5217771034031" target="_blank">
                <img class="btn-redes" src="assets/img/whatsapp.svg" alt="Whatsapp logo">
            </a>
            
        </div>
    </div>
</nav>
