import { Component, OnInit } from '@angular/core';
import { ServiviciosService } from '../../services/servicios.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-servicios-lite',
  templateUrl: './servicios-lite.component.html',
  styleUrls: ['./servicios-lite.component.css']
})
export class ServiciosLiteComponent implements OnInit {

  productos:any[] = [];

  constructor(private _productosService: ServiviciosService,
              private router:Router) {
    
      
   }

  ngOnInit(): void {
    this.productos = this._productosService.get4Servicios();
    console.log(this.productos)
  }

}
