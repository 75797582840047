<app-header></app-header>
<div class="barra-flotante">
    <app-navbar></app-navbar>
</div>

<div class="container-fluid">
    <h1>Servicios</h1>
    <hr>
    <h2>Electrónica</h2>
        <div class="card-columns">
            <div class="card" *ngFor="let producto of prod_electri">
                <img src="assets/img/servicios/{{producto.img}}" class="card-img-top d-block w-100" alt="...">
                <div class="card-body ">
                    <h5 class="card-title">{{ producto.nombre }}</h5>
                    <p class="card-text module" [innerHTML]="producto.descripcion "></p>
                    <a [routerLink]="['/servicio/electrica/',producto.id]" class="btn btn-primary btn-block">Ver más...</a>
                </div>
            </div>
        </div>
    <hr>
    <h2>Automotriz</h2>
        <div class="card-columns">
            <div class="card" *ngFor="let producto of prod_auto">
                <img src="assets/img/servicios/{{producto.img}}" class="card-img-top d-block w-100" alt="...">
                <div class="card-body ">
                    <h5 class="card-title">{{ producto.nombre }}</h5>
                    <p class="card-text module" [innerHTML]="producto.descripcion "></p>
                    <a [routerLink]="['/servicio/automotriz/',producto.id]" class="btn btn-primary btn-block">Ver más...</a>
                </div>
            </div>
        </div>
    <hr>
    <h2>Eléctrica</h2>    
        <div class="card-columns">
            <div class="card" *ngFor="let producto of prod_electro">
                <img src="assets/img/servicios/{{producto.img}}" class="card-img-top d-block w-100" alt="...">
                <div class="card-body ">
                    <h5 class="card-title">{{ producto.nombre }}</h5>
                    <p class="card-text module" [innerHTML]="producto.descripcion "></p>
                    <a [routerLink]="['/servicio/electronica/',producto.id]" class="btn btn-primary btn-block">Ver más...</a>
                </div>
            </div>
        </div>

</div>

<app-footer></app-footer>