<div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item ">
        <img src="assets/img/slide/transformadores_02.jpg" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item ">
        <img src="assets/img/slide/carro_01.jpg" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="assets/img/slide/eolicas_01.jpg" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="assets/img/slide/paneles_01.jpg" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item ">
        <img src="assets/img/slide/torre_01.jpg" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item active">
        <img src="assets/img/slide/transformadores_01.jpg" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="assets/img/slide/eolicas_02.jpg" class="d-block w-100" alt="...">
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>