<div class="container-fluid">

    <h1>Servicios</h1>
    <div class="card-columns" >
        <div class="card" *ngFor="let producto of productos">
            <img src="assets/img/servicios/{{producto.img}}" class="card-img-top img-prod" alt="...">
            <div class="card-body ">
                <h5 class="card-title">{{ producto.nombre }}</h5>
                <p class="card-text module">{{ producto.descripcion }}</p>
                <a [routerLink]="['/servicio/',producto.categoria,producto.id]" class="btn btn-primary btn-block">Ver más...</a>
            </div>
        </div>
    </div>

</div>
