<app-header></app-header>
<div class="barra-flotante">
    <app-navbar></app-navbar>
</div>

<div class="card">
    <div class="row g-0">
      <div class="col-lg-6 col-md-4 col-sm-12">
        <img src="assets/img/servicios/{{producto.img}}" alt="..." class="d-block w-100 rounded-lg">
      </div>
      <div class="col-lg-6 col-md-8 col-sm-12">
        <div class="card-body">
          <h1 class="card-title">{{producto.nombre}}</h1>
          <p class="card-text">{{ producto.descripcion }}</p>
        </div>
      </div>
    </div>
  </div>



<app-footer></app-footer>