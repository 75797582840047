<div id="contactanos-slide" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
        <div class="carousel-item active">
            <img src="assets/img/slide/contactanos.jpg" class="d-block w-100" alt="...">
            <div class="carousel-caption d-none d-md-block">
                <a [routerLink]="['/contacto']">
                <button type="button" class="btn btn-lg btn-success btn-contact">¡Contáctanos!</button>
                </a>
            </div>
        </div>
    </div>
</div>
