import { Component, OnInit } from '@angular/core';
import { ProductosService } from '../services/productos.service';
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.css']
})
export class ProductoComponent implements OnInit {

  public producto:any;
  public id:any; 

  constructor(private prodServ:ProductosService, private ruta:ActivatedRoute) {
    this.ruta.params.subscribe((param)=>{
      this.id = param['id'];
      this.producto = prodServ.getProducto(this.id);
    })
   }

  ngOnInit(): void {
  }

}
