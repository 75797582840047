<div class="container menuPill">
    <ul class="nav nav-pills nav-fill">
        <li class="nav-item">
            <a class="nav-link inicio " routerLinkActive="active" [routerLink]="['/home']">Inicio</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/nosotros']" >Nosotros</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/servicios']">Servicios</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/productos']" >Productos</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" >Nuestras Marcas</a>
        </li>
        <li class="nav-item ">
            <a class="nav-link fin" routerLinkActive="active" [routerLink]="['/contacto']">Contácto</a>
        </li>
    </ul>
</div>
