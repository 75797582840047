<footer class="bg-light text-center text-lg-start">
    <!-- Grid container -->
    <div class="container p-4">
      <!--Grid row-->
      <div class="row">

      </div>
      
    </div>
    <!-- Grid container -->
  
    <!-- Copyright -->
    <div class="text-center p-3 marca">
      © {{year}} Mobra | Tecnología - 
      <a class="text-dark" href="https://mobra.mx/">mobra.mx</a>
    </div>
    <!-- Copyright -->
  </footer>