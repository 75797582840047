import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contactanos-lite',
  templateUrl: './contactanos-lite.component.html',
  styleUrls: ['./contactanos-lite.component.css']
})
export class ContactanosLiteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
