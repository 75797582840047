<app-header></app-header>
<div class="barra-flotante">
    <app-navbar></app-navbar>
</div>
<app-carrusel></app-carrusel>
<div class="container-fluid">
    
    <!--
    <h2>Envianos un correo</h2>
    <div>
    <form>
        <div class="form-group">
            <label for="Nombre">Nombre</label>
            <input type="text" class="form-control" id="Nombre">
        </div>
        <div class="form-group">
            <label for="email">Correo</label>
            <input
                type="email"
                class="form-control"
                id="email"
                aria-describedby="emailHelp"
            >
        </div>
        <div class="form-group">
            <label for="Telefono">Telefono</label>
            <input type="text" class="form-control" id="Telefono">
        </div>
        <div class="form-group">
            <label for="exampleFormControlSelect2">Intereses</label>
            <select multiple class="form-control" id="exampleFormControlSelect2">
              <option>Electrónica y Comunicación</option>
              <option>Automotriz</option>
              <option>Eléctrica</option>
            </select>
          </div>
        <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="exampleCheck1">
            <label class="form-check-label" for="exampleCheck1">Validar</label>
        </div>
        <button type="submit" class="btn btn-primary btn-lg">Enviar</button>
    </form>
    </div>
    -->
    <div class="jumbotron mt-5">
        <h1 class="display-4">¡Permítenos colaborar contigo!</h1>
        <hr class="my-4">
        <p>
            <a href="tel:+5217771034031" target="_blank">
                <img class="btn-telefono" src="assets/img/telefono.svg" alt="Whatsapp logo">
            </a>    
            <a href="https://api.whatsapp.com/send?phone=+5217771034031" target="_blank">
                <img class="btn-whatsapp" src="assets/img/whatsapp.svg" alt="Whatsapp logo">
            </a> Tel:
            <a href="tel:+5217771034031">(777) 103 40 31</a>
        </p>
        <p>
            <a href="mailto:soporte@mobra.mx">soporte@mobra.mx</a> -   Atención a Clientes en Cuestiones Técnicas:
        </p>
        <p>
            <a href="mailto:ventas@mobra.mx">ventas@mobra.mx</a>   -   Contacto para requerimientos y cotizaciones:
        </p>
    </div>
</div>
<app-footer></app-footer>
